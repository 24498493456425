.Playlists {
  background-color: white;
  max-width: 750px;
  min-width: 320px;
  padding: 20px;
  border-radius: 25px;
  margin: auto;
}
.Playlists h3 {
  text-align: left;
  padding-bottom: 20px;
}

@media only screen and (max-width: 768px) {
}

@media only screen and (min-width: 769px) {
}
