*,
html {
  margin: 0;
  padding: 0;
}

body {
  background-color: whitesmoke;
  color: rgb(32, 32, 32);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode";
  margin-top: 50px;
}

/* NAV */

#name_box {
  font-size: 22px;
  padding-top: 50px;
}
#menu_box {
  float: left;
  height: 100%;
  width: 20vw;
  padding-left: 100px;
}

ul {
  margin: 0;
  padding-top: 50px;
  list-style: none;
  overflow: hidden;
}
ul li{
  padding-right: 50px;
}
li a {
  display: block;
  color: #414141;
  text-decoration: none;
  padding-bottom: 20px;
  font-size: 20;
}

li a:hover,
.menu-btn:hover {
  text-decoration: none;
  color: gray;
}

.menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.menu-icon {
  cursor: pointer;
  /* display: inline-block; */
  /* padding: 28px 20px; */
  margin-top: -15px;
  position: relative;
  user-select: none;
  float: right;
}

.menu-icon .navicon {
  background: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
  -bottom: 100px;
  margin:10px;
}

.menu-icon .navicon:before,
.menu-icon .navicon:after {
  background: #000;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.menu-icon .navicon:before {
  top: 5px;
}

.menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.menu-btn {
  display: none;
}

.menu-btn:checked ~ .menu {
  max-height: 340px;
}

.menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* MAIN */

main {
  text-align: center;
  font-size: 18px;
  min-width: 320px;
  padding-bottom: 50px;
  margin: auto;
}

main h1 {
  padding-bottom: 50px;
}

#contentWrapper {
  float: left;
  width: 70vw;
}

@media only screen and (max-width: 768px) {
  #menu_box {
    float: left;
    width: 100%;
    height: auto;
    padding-left: 0px;
  }
  #name_box {
    font-size: 22px;
    padding-top: 0px;
    text-align: center;
  }

  #name_box p{
    line-height: 10px;
  }

  ul {
    text-align: center;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
  ul li{
    padding-right: 0px;
  }
  ul li:first-child{
    padding-top:25px;
  }
  .menu {
    clear: both;
    transition: max-height 0.2s ease-out;
    position: relative;
    z-index: 2;
  }
  
  #contentWrapper {
    padding-top: 50px;
    width: 100vw;
  }
}

@media only screen and (min-width: 769px) {


  .menu-wrapper {
    height: 55px;
    width: 100%;
  }
  li {
    float: left;
    width: 100%;
  }
  .logo {
    line-height: 1;
  }
  li a {
    color: #000;
    /* border-right: 1px solid rgba(255, 255, 255, 0.2); */
  }
  .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .menu-icon {
    display: none;
  }
}
