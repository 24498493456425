a{
    color:inherit;
}
.VideoComponent{
    display: block;
    min-width: inherit;
    max-width: inherit;
    width: inherit;
    height: 90px;
    min-height: 90px;
    padding: 10px;
}

.VideoComponent:hover{
    border-radius: 25px;
    background-color: lightgray;
}

.VideoComponent img{
    float: left;
    padding-left: 10px;
}
.Description{
    float: left;
    width: 50%;
    height: inherit;
    padding-left: 10px;
    text-align: left;
    line-height: 70px;
}

.VideoCount{
    float: right;
    height: inherit;
    text-align: left;
    line-height: 90px;
}

@media only screen and (max-width: 768px) {
    .VideoComponent img{
        float: left;
        margin-left: -25px;
    }
    .Description h3{
        font-size: 12px;
    }
    .VideoCount{
        font-size: 8px;
    }
}

@media only screen and (min-width: 769px) {
}

