.Form {
  margin: 0 auto;
  padding-top: 1em;
  max-width: 600px;
  min-width: 320px;
  text-align: left;
}

.Form h3{
    padding-top: 2em;
}

.resumeForm {
  max-width: 600px;
  margin: 0 auto;
}

.Form hr {
  border: 1px solid;
  color: lightgray;
  margin: 1rem;
}

.Form .sectionInformation {
  margin-bottom: 2rem;
}

.Form input[type=submit] {
    font-size: 16px;
    padding: 1em 2em 1em 2em;
    float: right;
    
}

.field-wrapper input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.field-wrapper textarea {
  width: 100%;
  height: 10rem;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.field-wrapper ul {
  list-style-type: disc;
  padding: 1rem;
}
.field-wrapper li {
  margin-left: 1rem;
}
